/** @format */

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
   .react-grid-layout {
        position: relative;
        transition: height 200ms ease;
    }
    .react-grid-item {
        /* transition: all 200ms ease;
        transition-property: left, top; */
        background: #fff;
        border-radius: 5px;
    }
    .react-grid-layout .react-draggable{
        cursor: all-scroll;
    }

    .react-grid-item img {
        pointer-events: none;
        user-select: none;  
    }
    .react-grid-item.cssTransforms {
        transition-property: transform;
    }
    .react-grid-item.resizing {
        z-index: 1;
        will-change: width, height;
    }

    .react-grid-item.react-draggable-dragging {
        z-index: 3;
        /* will-change: transform; */
    }

    .react-grid-item.dropping {
        visibility: hidden;
    }

    .react-grid-item.react-grid-placeholder {
        background: #b3e0fa;
        opacity: 0.2;
        /* transition-duration: 100ms; */
        z-index: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .react-grid-item > .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
    }

    .react-grid-item > .react-resizable-handle::after {
        content: "";
        position: absolute;
        right: 3px;
        bottom: 3px;
        width: 5px;
        height: 5px;
        border-right: 2px solid #65c5fd;
        border-bottom: 2px solid #65c5fd;
        opacity: 0;
    }

    .react-grid-item:hover .react-resizable-handle::after {
        opacity: 1;
    }

    .react-resizable-hide > .react-resizable-handle {
        display: none;
    }

    .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
        bottom: 0;
        left: 0;
        cursor: sw-resize;
        transform: rotate(90deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
        bottom: 0;
        right: 0;
        cursor: se-resize;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
        top: 0;
        left: 0;
        cursor: nw-resize;
        transform: rotate(180deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
        top: 0;
        right: 0;
        cursor: ne-resize;
        transform: rotate(270deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
    .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
        top: 50%;
        margin-top: -10px;
        cursor: ew-resize;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
        left: 0;
        transform: rotate(135deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
        right: 0;
        transform: rotate(315deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
    .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
        left: 50%;
        margin-left: -10px;
        cursor: ns-resize;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
        top: 0;
        transform: rotate(225deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
        bottom: 0;
        transform: rotate(45deg);
    }




/* scroll bar css */
    html {
    * { 
        scrollbar-width: thin;
    }
    }
    ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-image: ${(props) =>
      `linear-gradient(135deg, ${props.theme.scrollbar.colorBg} 0%, ${props.theme.scrollbar.colorBg} 72%, ${props.theme.scrollbar.colorBg} 100%)`} ;
    }
    ::-webkit-scrollbar-track {
    border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-image: linear-gradient(135deg, rgba(204, 204, 204, 0.8) 0%, rgba(204, 204, 204, 0.8) 72%, rgba(204, 204, 204, 0.8) 100%);
    transition: all .2s;
    border-radius: 0.4rem;
    }
    ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(95, 95, 95, 0.7);
    }
`;
