import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useRef,
} from "react";
import { path, map } from "ramda";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { sharedUtils, sharedOptions } from "horizon-shared-lib";
import dayjs from "dayjs";

import * as api from "api";
import Header from "./Header";
import ItemContent from "./ItemContent";
import ErrorMsg from "./ErrorMsg";
import DashboardContext from "hook/dashboard/context";
import ResizeCssLoading from "components/shared/elements/ResizeCssLoading";
import CssLoading from "components/shared/elements/CssLoading";
import { useUpdateData, onError } from "utils/useQueryConfig";
import { typesExist, auid, createAt, processConfig } from "utils/stringUtil";

const StyledLoading = styled.div`
  width: calc(100% - 2px);
  height: calc(100% - 85px);
  position: absolute;
  left: 1px;
  top: 65px;
  z-index: 10;
  background: ${(props) => props.theme.background.itemPrimary};
`;

interface MyStyledComponentProps {
  isactive: any;
}

const StyledItem = styled.div<MyStyledComponentProps>`
  height: 100%;
  outline-offset: -1px;
  border-radius: 5px;
  background: ${(props) => props.theme.background.itemPrimary};
  .hover-show {
    opacity: ${(props: any) => (props.isactive ? 1 : 0)};
  }
  outline: ${(props: any) => props.isactive && "1px solid #229bff"};
  a {
    color: ${(props: any) => (props.isactive ? "#229bff" : "#2e2e2e")};
  }
  &:hover {
    box-shadow: 0px 2px 6px #cdeeff;
    outline: 1px solid #229bff;
    .hover-show {
      opacity: 1;
    }
    a div {
      color: #229bff;
    }
  }
`;

const StyledContainer = styled.div`
  height: calc(100% - 40px);
  padding: 15px 20px 20px;
  user-select: none;
`;

const NoData = styled.div`
  color: #949494;
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const defalutdata = {
  groupByDisplayNames: null,
  groupByValues: [null],
  indicatorNames: ["Device Register_App Launch"],
  indicators: [
    {
      groupBys: [
        {
          groupByValues: [],
          startDates: [
            {
              originalAmount: 6387,
              retentionOnDates: [
                {
                  amount: 53,
                  onDate: "2023-08-20",
                },
                {
                  amount: 39,
                  onDate: "2023-08-21",
                },
                {
                  amount: 25,
                  onDate: "2023-08-22",
                },
                {
                  amount: 26,
                  onDate: "2023-08-23",
                },
                {
                  amount: 18,
                  onDate: "2023-08-24",
                },
                {
                  amount: 13,
                  onDate: "2023-08-25",
                },
                {
                  amount: 9,
                  onDate: "2023-08-26",
                },
              ],
              startDate: "2023-08-20",
            },
            {
              originalAmount: 5832,
              retentionOnDates: [
                {
                  amount: 74,
                  onDate: "2023-08-21",
                },
                {
                  amount: 46,
                  onDate: "2023-08-22",
                },
                {
                  amount: 27,
                  onDate: "2023-08-23",
                },
                {
                  amount: 13,
                  onDate: "2023-08-24",
                },
                {
                  amount: 21,
                  onDate: "2023-08-25",
                },
                {
                  amount: 11,
                  onDate: "2023-08-26",
                },
                {
                  amount: 5,
                  onDate: "2023-08-27",
                },
              ],
              startDate: "2023-08-21",
            },
            {
              originalAmount: 5378,
              retentionOnDates: [
                {
                  amount: 60,
                  onDate: "2023-08-22",
                },
                {
                  amount: 51,
                  onDate: "2023-08-23",
                },
                {
                  amount: 25,
                  onDate: "2023-08-24",
                },
                {
                  amount: 11,
                  onDate: "2023-08-25",
                },
                {
                  amount: 9,
                  onDate: "2023-08-26",
                },
                {
                  amount: 6,
                  onDate: "2023-08-27",
                },
                {
                  amount: 13,
                  onDate: "2023-08-28",
                },
              ],
              startDate: "2023-08-22",
            },
          ],
        },
      ],
      indicatorName: "Device Register_App Launch",
    },
  ],
  onDates: [
    "2023-08-20",
    "2023-08-21",
    "2023-08-22",
    "2023-08-23",
    "2023-08-24",
    "2023-08-25",
    "2023-08-26",
  ],
  startDates: ["2023-08-20", "2023-08-21", "2023-08-22"],
};

const loadStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

const { ChartTypes } = sharedOptions;
const {
  showUpdateAt,
  dateAt,
  outOfDate,
  getTZ,
  useIntervalAsync,
  errorMsgByCode,
} = sharedUtils;

function Item({ i, nodeRef }: { i: any; nodeRef?: any }) {
  const params = useParams();
  const projectId = path(["projectId"], params);
  const [isActive, setActive] = useState<boolean>(false);
  const [chartName, setChartName] = useState<string>(i.widget.chartType);
  const [dataUpdateAt, setDataUpdateAt] = useState<string>("");
  const [timeScope, setTimeScope] = useState("TOTAL");
  const [data, setData] = useState(null);
  const [ratioData, setRatioData] = useState(null);
  const [errors, setErrors] = useState<string | null>();
  const [isLoaded, toggleLoaded] = useState(false);
  const [stale, toggleStale] = useState(true);
  const { state } = useContext(DashboardContext);
  const abortControllerRef = useRef<any>(null);
  const abortControllerComparisonRef = useRef<any>(null);
  const [chartFilter, setChartFilter] = useState({});

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { widgets, theme, project, dashboard } = state;

  const environmentId: string = path(["environmentId"], dashboard);

  const projectCreateAt = createAt(project);

  const widget = useMemo(() => {
    return path(["widget"], i);
  }, [i]);

  const analyisisType = useMemo(() => {
    return path(["widgetType"], widget);
  }, [widget]);

  const analysis = useMemo(() => {
    return path(["analysis"], widget);
  }, [widget]);

  const config = processConfig(path(["config"], i));
  const dynamicTime = path(["dynamicTime"], config);

  const { endAt, startAt } = dateAt(analysis, dynamicTime, projectCreateAt);

  console.log(state);
  console.log(widgets);
  console.log(i);

  const {
    mutate: ratioMutate,
    isLoading: ratioLoading,
    error: ratioonError,
  } = useUpdateData(
    null,
    (value) => {
      const abortController = new AbortController();
      abortControllerComparisonRef.current = abortController;
      const { signal } = abortController;
      return api.fetchRelativeComparison(projectId, value, signal);
    },
    null,
    true
  );

  const {
    mutate: Mutate,
    isLoading: loading,
    error,
  } = useUpdateData(null, (value) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const { signal } = abortController;
    if (analyisisType === "FUNNEL") {
      return api.queryFunnelAnalysis(projectId, value, signal);
    }
    if (analyisisType === "RETENTION") {
      return api.queryRetentionAnalysis(projectId, value, signal);
    }
    return api.queryEventAnalysis(projectId, value, signal);
  });

  const fetchRatio = useCallback(
    (q: any, ans: any) => {
      let numberTypes = ans
        ? path(["numberTypes"], ans)
        : path(["numberTypes"], widget);
      numberTypes = numberTypes || [];
      abortControllerComparisonRef.current?.abort();
      ratioMutate(
        {
          ...q,
          compare: {
            yoyRule: path(["compare", "yoyRule"], widget) || "YOY_DAY",
            showMomRatio: typesExist("MOM", numberTypes),
            showYoyRatio: typesExist("YOY", numberTypes),
          },
        },
        {
          onSuccess(datasource: any) {
            setRatioData(datasource);
          },
          onError(e: any) {
            if (e.code !== 602 && e.code !== 404) {
              onError(e);
            }
          },
        }
      );
    },
    [ratioMutate, widget]
  );

  const fetchQuery = useCallback(
    (result?: any, IsInWidget?: false) => {
      abortControllerRef.current?.abort();
      const ans = path(["analysis"], result);
      let query = ans || analysis;

      if (!IsInWidget) {
        query = { ...query, endAt: endAt, startAt: startAt };
      }

      const err: any = outOfDate(query);
      if (err) {
        setErrors(err);
        return "";
      } else {
        setErrors("");
      }

      const mutateOption = {
        analysis: { ...query, timezone: getTZ() },
        environmentId: environmentId,
      };

      Mutate(mutateOption, {
        onSuccess(datasource: any) {
          setData(datasource);
          setDataUpdateAt(showUpdateAt(datasource));
        },
        onError(e: any) {
          if (e.code !== 602 && e.code !== 404) {
            onError(e);
          }
        },
      });
      if (
        analyisisType === "EVENT" &&
        (typesExist("MOM", path(["numberTypes"], widget)) ||
          typesExist("YOY", path(["numberTypes"], widget)))
      ) {
        fetchRatio(mutateOption, result);
      }
    },

    [
      Mutate,
      widget,
      analysis,
      startAt,
      endAt,
      environmentId,
      fetchRatio,
      analyisisType,
    ]
  );

  useEffect(() => {
    const err = errorMsgByCode(error);
    setErrors(err);
  }, [error]);

  const fetchQueryByPost = useCallback(() => {
    if (inView) {
      fetchQuery();
    }
  }, [inView, fetchQuery]);

  const intervalFetchQuery = useIntervalAsync(
    fetchQueryByPost,
    600000,
    inView,
    loading
  );

  console.log(inView);

  useEffect(() => {
    if (inView) {
      toggleLoaded(true);
    }
  }, [inView]);

  useEffect(() => {
    if (isLoaded && stale) {
      fetchQuery();
      toggleStale(false);
    }
  }, [isLoaded, stale, fetchQuery]);

  useEffect(() => {
    console.log(i.widget);
    setChartName(i.widget.chartType);
  }, [i]);

  console.log(chartName);

  const onRefresh = (isNeedLoading: boolean = false) => {
    if (isNeedLoading) {
      setData(null);
    }
    fetchQuery();
  };

  const memoItem = useMemo(() => {
    return { ...i };
  }, [i]);

  const momeData = useMemo(() => {
    return data;
  }, [data]);

  const momeChartName = useMemo(() => {
    return chartName;
  }, [chartName]);

  const memoTimeScope = useMemo(() => {
    return timeScope;
  }, [timeScope]);

  const momeRatioData = useMemo(() => {
    return ratioData;
  }, [ratioData]);

  const ChartNode = ChartTypes[chartName];

  console.log(dataUpdateAt);

  return (
    <StyledItem isactive={isActive ? 1 : 0}>
      <StyledContainer ref={ref}>
        <Header
          dataUpdateAt={dataUpdateAt}
          projectCreateAt={projectCreateAt}
          isActive={isActive}
          setActive={setActive}
          setTimeScope={setTimeScope}
          timeScope={timeScope}
          nodeRef={nodeRef}
          onRefresh={onRefresh}
          item={i}
        />
        {!data && loading && (
          <StyledLoading>
            <ResizeCssLoading size="middle" style={loadStyle} />
          </StyledLoading>
        )}
        {ChartNode && data && !errors ? (
          <ItemContent
            item={memoItem}
            data={momeData}
            ratioData={momeRatioData}
            chartName={momeChartName}
            timeScope={memoTimeScope}
            chartFilter={chartFilter}
            setChartFilter={setChartFilter}
            nodeRef={nodeRef}
            theme={theme}
            fetchQuery={fetchQuery}
            setData={setData}
          />
        ) : (
          <NoData>
            {!loading && errors && (
              <ErrorMsg
                data={[]}
                projectId={projectId}
                errors={errors}
                data-auid={auid("insights", "dashboard", `widget_error_msg`)}
              />
            )}
          </NoData>
        )}
      </StyledContainer>
    </StyledItem>
  );
}

export default Item;
