const theme = {
  scrollbar: {
    colorBg: "#ECECEC",
  },
  colors: {
    primary: "#2e2e2e",
    primary2: "#949494",
    description: "#4f4f4f",
    disabledPrimary: "#d3d1d1",
    retention: {
      table: {
        value: "#000000e0",
        subValue: "#949494",
      },
    },
  },
  background: {
    primary: "#f2f3f5",
    itemPrimary: "#fff",
    table: "#fff",
    tableTdHover: "#fff",
    emptyTd: "#fafafa",
    widget: {
      table: {
        header: "#fafafa",
      },
    },
  },
  fontSize: "16px",
  antd: {},
};

export default theme;
