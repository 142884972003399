const theme = {
  scrollbar: {
    colorBg: "#4F4F4F",
  },
  colors: {
    primary: "#fff",
    primary2: "#dbd9d9",
    description: "#F2F3F5",
    disabledPrimary: "#353434",
    retention: {
      table: {
        value: "#fff",
        subValue: "#fff",
      },
    },
  },
  background: {
    primary: "#2E2E2E",
    itemPrimary: "#4F4F4F",
    table: "#4F4F4F",
    tableTdHover: "#616060",
    emptyTd: "#636262",
    widget: {
      table: {
        header: "#949494",
      },
    },
  },
  fontSize: "16px",
  antd: {
    components: {
      Table: {
        colorBgContainer: "#4F4F4F",
        colorBorder: "#F2F3F5",
        colorInfoBgHover: "red",
        colorInfoHover: "red",
        colorPrimaryBgHover: "red",
        colorPrimaryHover: "#fff",
      },
      Radio: {
        colorBgContainer: "#2E2E2E",
        colorInfoText: "red",
        colorPrimary: "#fff",
        colorPrimaryHover: "#fff",
      },
      Select: {
        colorBgContainer: "#2E2E2E",
        colorInfoText: "red",
        colorPrimary: "#fff",
        colorPrimaryHover: "#fff",
      },
    },
  },
};

export default theme;
