/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import { path } from "ramda";
import { LooseStaticObject } from "constants/interface";
import { TenantId } from "utils/constants";
import { getAndLockToken, unlockToken } from "./tokenHelper";
import {
  keysToCamel,
  keysToSnack,
  camelToSnack,
  objToQuery,
} from "utils/stringUtil";

export const requestOptionsCreator =
  (token: string) =>
  (method: string, body: any, retoken: string, pub: string, addSignal: any) => {
    const headers: LooseStaticObject = {
      "content-type": "application/json",
      "X-HPBP-Tenant-ID": TenantId,
    };
    const signal: any = {};
    if (addSignal) {
      signal.signal = addSignal;
    }
    if (!pub) {
      headers.authorization = `Bearer ${retoken}`;
    }
    return {
      method,
      body: body ? JSON.stringify(body) : null,
      headers,
      ...signal,
    };
  };

export const requestCreator =
  (apiBase: string, requestOptions: any) =>
  async ({
    url,
    queryParams,
    body,
    method,
    signal,
  }: {
    url: string;
    queryParams: any;
    body: any;
    method: string;
    signal: any;
  }) => {
    const { accessToken } = await getAndLockToken();
    const result = {
      token: accessToken,
    };

    const options = requestOptions(
      method || "GET",
      keysToSnack(body),
      result.token,
      false,
      signal
    );

    const query =
      queryParams &&
      Object.keys(queryParams).filter((k) => queryParams[k] !== null).length > 0
        ? `?${objToQuery(camelToSnack, queryParams)}`
        : "";
    try {
      const resp = await fetch(
        url.startsWith("http") ? `${url}${query}` : `${apiBase}${url}${query}`,
        options
      );

      let data: LooseStaticObject = {};
      try {
        data.result = keysToCamel(await resp.json());
      } catch (e) {
        // just ignore this error
      }

      if (resp.status >= 400) {
        throw { ...data.result, status: resp.status };
      }
      return data.result;
    } catch (error: any) {
      if (!error.status && error.message === "Failed to fetch") {
      }

      if (error.status === 401) {
      }

      throw { ...keysToCamel(error), name: error.name };
    } finally {
      unlockToken();
    }
  };
