import React, { useRef, useState, useEffect, useContext, useMemo } from "react";
import { path, map } from "ramda";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { sharedUtils } from "horizon-shared-lib";

import Empty from "components/shared/elements/Empty";
import * as api from "api";
import Layout from "components/Dashboards/Layout";
import StyledSpin from "components/shared/elements/StyledSpin";
import { useTranslation } from "locales";
import { auid } from "utils/stringUtil";
import DashboardContext from "hook/dashboard/context";
import {
  DASHBOARD_WIDGET,
  PROJECT_DETAIL,
  DASHBOARD_DETAIL,
} from "hook/dashboard/reducer";

const StyledContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.background.primary};
  display: flex;
  justify-content: center;
`;

const { getWidget } = sharedUtils;

const queryOption = {
  refetchOnWindowFocus: false,
  refetchInterval: 5 * 60 * 1000 - 1000,
  retry: 1,
};

const Node = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div
    data-auid={auid("insights", "dashboard", `empty`)}
    style={{
      display: "flex",
      alignItems: "center",
      height: "97vh",
    }}
  >
    <Empty title={title} description={<>{description}</>} />
  </div>
);

function Dashboard() {
  const { t } = useTranslation("Dashboards");
  const params = useParams();
  const projectId = path(["projectId"], params);
  const dashboardId = path(["dashboardId"], params);
  const [scrolling, setScrolling] = useState(false);
  const scrollContainerRef = useRef(null);

  const { state, dispatch } = useContext(DashboardContext);

  console.log(state);

  const { widgets } = state;

  const { isSuccess: projectIsSuccess, data: projectData } = useQuery(
    ["project"],
    () => api.fetchProject(projectId),
    queryOption
  );

  const {
    isSuccess: dashboardIsSuccess,
    isError: dashboardIsError,
    data: dashboardData,
    isFetchedAfterMount,
  } = useQuery(
    [projectId, "dashboard"],
    () => api.fetchDashboard(projectId, dashboardId),
    { ...queryOption }
  );

  console.log(dashboardData);

  const items = useMemo(() => {
    return [
      {
        layout: {
          h: 8,
          w: 4,
          x: 0,
          y: 0,
        },
        widget: {
          retentionWidget: {
            id: "36590c86-a882-42ad-9346-f3939fa87a2c",
            chartType: "RETENTION_LINE",
            dashboardIds: ["6e1e36cc-7cb3-48e4-9f29-3a12954505ec"],
            description: "",
            name: "lineWidget1",
            projectId: "efaa8dfc-8a43-4301-8e22-db8ebe4dcddb",
            analysis: {
              calculateBy: "RETENTION",
              endAt: 1692719999,
              groupBys: null,
              indicators: [
                {
                  indicatorName: "Device Register_App Launch",
                  initial: {
                    associatedAttribute: "",
                    eventName: "device_register",
                    filter: {
                      filters: null,
                    },
                  },
                  return: {
                    associatedAttribute: "",
                    eventName: "app_launch",
                    filter: {
                      filters: null,
                    },
                  },
                },
              ],
              retentionFrom: 2,
              retentionTo: 7,
              startAt: 1692460800,
              timezone: "Asia/Shanghai",
              unit: "DAY",
            },
            numberTypes: null,
            meta: {
              createdAt: 1692764591,
              createdBy: "c727de3b-93fa-4b18-a876-cc26cfc072ef",
              updatedAt: 1693274961,
              updatedBy: "c727de3b-93fa-4b18-a876-cc26cfc072ef",
            },
          },
          widgetType: "RETENTION",
        },
      },
      {
        layout: {
          h: 8,
          w: 2,
          x: 2,
          y: 16,
        },
        widget: {
          retentionWidget: {
            id: "56d2d7f9-01f9-4abb-81e9-dae992459eba",
            chartType: "RETENTION_TABLE",
            dashboardIds: ["6e1e36cc-7cb3-48e4-9f29-3a12954505ec"],
            description: "",
            name: "test01",
            projectId: "efaa8dfc-8a43-4301-8e22-db8ebe4dcddb",
            analysis: {
              calculateBy: "RETENTION",
              endAt: 1692719999,
              groupBys: null,
              indicators: [
                {
                  indicatorName: "Device Register",
                  initial: {
                    associatedAttribute: "",
                    eventName: "device_register",
                    filter: {
                      filters: null,
                    },
                  },
                  return: {
                    associatedAttribute: "",
                    eventName: "app_launch",
                    filter: {
                      filters: null,
                    },
                  },
                },
                {
                  indicatorName: "App Launch",
                  initial: {
                    associatedAttribute: "",
                    eventName: "app_launch",
                    filter: {
                      filters: null,
                    },
                  },
                  return: {
                    associatedAttribute: "",
                    eventName: "notification_show",
                    filter: {
                      filters: null,
                    },
                  },
                },
              ],
              retentionFrom: 2,
              retentionTo: 3,
              startAt: 1692115200,
              timezone: "Asia/Shanghai",
              unit: "DAY",
            },
            numberTypes: null,
            meta: {
              createdAt: 1692775187,
              createdBy: "c727de3b-93fa-4b18-a876-cc26cfc072ef",
              updatedAt: 1693275011,
              updatedBy: "c727de3b-93fa-4b18-a876-cc26cfc072ef",
            },
          },
          widgetType: "RETENTION",
        },
      },

      {
        layout: {
          h: 12,
          w: 4,
          x: 0,
          y: 0,
        },
        widget: {
          widgetType: "EVENT",
          eventWidget: {
            id: "c4d4bc0e-6128-4636-a8fd-3386ff30c6f3",
            analysis: {
              endAt: 1687881599,
              filter: {
                filters: [],
                relation: "AND",
              },
              groupBy: ["user.$user_province"],
              isRollup: false,
              measures: [
                {
                  aggregator: "COUNT",
                  eventName: "device_register",
                  filter: {
                    filters: [
                      {
                        conditions: [
                          {
                            field: "user.$user_country",
                            function: "EQUAL",
                            params: ["中国"],
                          },
                        ],
                        relation: "AND",
                      },
                    ],
                    relation: "AND",
                  },
                  name: "设备激活 - Total times",
                },
              ],
              sortBy: [],
              startAt: 1678809600,
              unit: "DAY",
            },
            chartType: "CHINA_MAP",
            compare: {
              showMomRatio: false,
              showYoyRatio: false,
              yoyRule: "YOY_WEEK",
            },
            dashboardIds: ["5862e6e7-7dd0-4447-b683-3680b1405e84"],
            description: "",
            dynamicTime: "2023-03-15,0,day",
            name: "Map View",
            numberTypes: null,
            projectId: "53f6e84e-25a4-461a-852b-03aab2c1cdd2",
            meta: {
              createdAt: 1686548325,
              createdBy: "04a9dd61-8bd6-4c23-99be-481af6ff6a0f",
              updatedAt: 1690871284,
              updatedBy: "191b20ae-58cf-4306-8528-fc44b5b74c5c",
            },
          },
        },
      },

      {
        layout: {
          h: 8,
          w: 2,
          x: 0,
          y: 12,
        },
        widget: {
          widgetType: "EVENT",
          eventWidget: {
            id: "4490995f-5843-4359-b016-2ba275755dec",
            analysis: {
              endAt: 1689004799,
              filter: {
                filters: [],
                relation: "AND",
              },
              groupBy: ["user.device_chassis"],
              isRollup: false,
              measures: [
                {
                  aggregator: "COUNT",
                  eventName: "device_register",
                  filter: {
                    filters: [],
                  },
                  name: "设备激活 - Total times",
                },
              ],
              sortBy: [],
              startAt: 1678809600,
              unit: "WEEK",
            },
            chartType: "PIE",
            compare: {
              showMomRatio: false,
              showYoyRatio: false,
              yoyRule: "YOY_WEEK",
            },
            dashboardIds: ["5862e6e7-7dd0-4447-b683-3680b1405e84"],
            description: "",
            dynamicTime: "2023-03-15,0,day",
            name: "Model allocation",
            numberTypes: null,
            projectId: "53f6e84e-25a4-461a-852b-03aab2c1cdd2",
            meta: {
              createdAt: 1686549250,
              createdBy: "04a9dd61-8bd6-4c23-99be-481af6ff6a0f",
              updatedAt: 1688959534,
              updatedBy: "04a9dd61-8bd6-4c23-99be-481af6ff6a0f",
            },
          },
        },
      },

      {
        layout: {
          h: 8,
          w: 4,
          x: 0,
          y: 20,
        },
        widget: {
          widgetType: "EVENT",
          eventWidget: {
            id: "33ae0403-18a7-497b-8ce3-2165b7db36b8",
            analysis: {
              endAt: 1690905599,
              filter: {
                filters: [
                  {
                    conditions: [
                      {
                        field: "user.device_chassis",
                        function: "NOT_CONTAIN",
                        params: ["23C1"],
                      },
                    ],
                    relation: "AND",
                  },
                  {
                    conditions: [
                      {
                        field: "user.device_chassis",
                        function: "NOT_CONTAIN",
                        params: ["1C23"],
                      },
                    ],
                    relation: "AND",
                  },
                ],
                relation: "AND",
              },
              groupBy: ["user.device_chassis"],
              isRollup: false,
              measures: [
                {
                  aggregator: "COUNT",
                  eventName: "device_register",
                  filter: {
                    filters: [],
                  },
                  name: "设备激活 - Total times",
                },
              ],
              sortBy: [],
              startAt: 1682870400,
              unit: "DAY",
            },
            chartType: "BAR",
            compare: {
              showMomRatio: false,
              showYoyRatio: false,
              yoyRule: "YOY_WEEK",
            },
            dashboardIds: ["5862e6e7-7dd0-4447-b683-3680b1405e84"],
            description: "",
            dynamicTime: "2023-05-01,0,day",
            name: "Before 23C1 platforms",
            numberTypes: ["SUM"],
            projectId: "53f6e84e-25a4-461a-852b-03aab2c1cdd2",
            meta: {
              createdAt: 1686548727,
              createdBy: "04a9dd61-8bd6-4c23-99be-481af6ff6a0f",
              updatedAt: 1690858002,
              updatedBy: "04a9dd61-8bd6-4c23-99be-481af6ff6a0f",
            },
          },
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch({
      type: DASHBOARD_WIDGET,
      payload: map((i: any) => {
        const widget: any = getWidget(i.widget);
        let layout = {};
        if (!i.layout) {
          layout = { layout: { h: 8, w: 2, x: 2, y: 0 } };
        }
        return { ...i, ...layout, widget: widget, widgetId: widget.id };
      }, path(["items"], dashboardData) || []),
    });
  }, [dispatch, dashboardData]);

  useEffect(() => {
    dispatch({
      type: PROJECT_DETAIL,
      payload: projectData,
    });
  }, [dispatch, projectData]);

  useEffect(() => {
    dispatch({
      type: DASHBOARD_DETAIL,
      payload: dashboardData,
    });
  }, [dispatch, dashboardData]);

  const exsitWidgets = (widgets || []).length > 0;

  const noPermissonNode = ((projectIsSuccess &&
    isFetchedAfterMount &&
    exsitWidgets) ||
    dashboardIsError) && (
    <div
      data-auid={auid("insights", "dashboard", `empty`)}
      style={{
        display: "flex",
        alignItems: "center",
        height: "97vh",
      }}
    >
      <Empty
        title="No access to dashboard" // to Get Insights
        description={
          <>
            Apologies, you currently lack the authorization to access this
            dashboard.
          </>
        }
      />
    </div>
  );

  const emptyNode = dashboardIsSuccess && (widgets || []).length === 0 && (
    <Node title="No widgets yet" description="" />
  );

  return (
    <StyledContainer>
      <div style={{ width: "calc(100% - 40px)" }}>
        <StyledSpin
          spinning={!(dashboardIsSuccess || dashboardIsError)}
          delay={500}
        >
          {emptyNode}
          {!exsitWidgets && noPermissonNode}
          <Layout />
        </StyledSpin>
      </div>
    </StyledContainer>
  );
}

export default Dashboard;
