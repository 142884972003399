/** @format */

import React, { memo, FC } from "react";
import ReactDOM from "react-dom";
import { sharedOptions } from "horizon-shared-lib";
import { useTranslation } from "locales";

interface ItemContentValues {
  theme?: string;
  item?: any;
  chartName: string;
  data: any;
  ratioData: any;
  fetchQuery?: any;
  nodeRef: any;
  timeScope: string;
  setData?: any;
  chartFilter: any;
  setChartFilter: any;
}

const { ChartTypes } = sharedOptions;

const ItemContent: FC<ItemContentValues> = memo(
  ({
    theme,
    item,
    chartName,
    data,
    ratioData,
    timeScope,
    chartFilter,
    setChartFilter,
    fetchQuery,
    setData,
    nodeRef,
  }) => {
    const ChartNode = ChartTypes[chartName];
    return (
      <ChartNode
        headerHeight={45}
        theme={theme}
        item={item}
        data={data}
        timeScope={timeScope}
        ratioData={ratioData}
        nodeRef={nodeRef}
        fetchQuery={fetchQuery}
        setData={setData}
        useTranslation={useTranslation}
        ReactDOM={ReactDOM}
        chartFilter={chartFilter}
        setChartFilter={setChartFilter}
      />
    );
  }
);

export default ItemContent;
