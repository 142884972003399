import React, { useReducer } from "react";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";

import Root from "./pages";
import dashboardContext from "./hook/dashboard/context";
import dashboardReducer from "./hook/dashboard/reducer";

function ContextProvider({
  theme,
  projectId,
}: {
  theme: any;
  projectId: string;
}) {
  const { initState, reducer } = dashboardReducer;
  const [state, dispatch] = useReducer(reducer, initState);
  console.log(state);

  return (
    <dashboardContext.Provider
      value={{ state: { ...state, projectId, theme }, dispatch: dispatch }}
    >
      <BrowserRouter>
        <Root />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </dashboardContext.Provider>
  );
}

export default ContextProvider;
