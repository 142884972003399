/**
 * /* eslint-disable max-lines
 *
 * @format
 */

import * as creator from "utils/apiHelper";
import { init as initToken, TokenOptions } from "utils/tokenHelper";

let request: any;

export const init = async (
  token: string,
  apiBase: any,
  tokenOptions: TokenOptions
) => {
  await initToken(tokenOptions);
  const { requestCreator, requestOptionsCreator } = creator;
  request = requestCreator(apiBase, requestOptionsCreator(token));
};

export const fetchProject = (
  projectId: string | undefined,
  queryParams?: any
) => request({ url: `/v1/projects/${projectId}`, queryParams });
// Dashboards

export const fetchDashboard: (
  projectId: string | undefined,
  dashboardId: string | undefined
) => Promise<any> = async (projectId, dashboardId) => {
  return await request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}`,
  });
};

export const queryEventAnalysis = async (
  projectId: string | undefined,
  body: any,
  signal?: any
) => {
  let result: any = await request({
    url: `/v1/projects/${projectId}/events/analysis`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};

export const queryFunnelAnalysis = async (
  projectId: string | undefined,
  body: any,
  signal?: any
) => {
  let result: any = await request({
    url: `/v1/projects/${projectId}/funnel/analysis`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};

export const queryRetentionAnalysis = async (
  projectId: string | undefined,
  body: any,
  signal?: any
) => {
  let result: any = await request({
    url: `/v1/projects/${projectId}/retention/analysis`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};

export const fetchRelativeComparison = async (
  projectId: string | undefined,
  body: any,
  signal?: any
) => {
  let result: any = await request({
    url: `/v1/projects/${projectId}/events/compare`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};
