/**
 * /* eslint-disable max-lines
 *
 * @format
 */
import React, { createContext } from "react";
import initState from "./reducer";

const entity = {
  state: initState,
  dispatch: () => undefined,
};

const PipelineContext = createContext<any>(entity);

export default PipelineContext;
