/* eslint-disable import/no-anonymous-default-export */
// import ClientOAuth2 from "client-oauth2";
import qs from "qs";

var DEFAULT_HEADERS = {
  "content-type": "application/json",
  // Accept: "application/json, application/x-www-form-urlencoded",
  // "Content-Type": "application/x-www-form-urlencoded",
};

export interface Token {
  tokenType: string;
  accessToken: string;
}

export interface TokenInfo {
  token: Token | null;
  expiresIn: number | null;
  error?: any | null;
}

export interface TokenOptions {
  clientId: string;
  //   clientSecret: string;
  accessTokenUri: string;
}

export default ({ clientId, accessTokenUri }: TokenOptions) => {
  // console.log(
  //   "rest credentials token config",
  //   JSON.stringify(
  //     {
  //       clientId,
  //       clientSecret,
  //       accessTokenUri,
  //     },
  //     null,
  //     4
  //   )
  // );

  // let sdkRestAuth: ClientOAuth2 | null = null;

  const headers = {
    ...DEFAULT_HEADERS,
    // "X-HPBP-Tenant-ID": TenantId,
  };

  let getToken = async () => {
    let tokenInfo: TokenInfo | null = null;

    let apiStartTime = new Date();
    try {
      const body: any = JSON.stringify({
        client_id: clientId,
        //   grant_type: "client_credentials",
        //   client_secret: clientSecret,
      });
      let response = await fetch(accessTokenUri, {
        method: "POST",
        headers: headers,
        body: body,
      });
      let { access_token, expires_in, token_type } = await response.json();
      tokenInfo = {
        token: {
          tokenType: token_type,
          accessToken: access_token,
        },
        expiresIn: Number(expires_in),
      };
    } catch (error) {
      console.error(
        `Credentials get token error ${apiStartTime.getTime()}`,
        error
      );
      tokenInfo = {
        token: null,
        expiresIn: null,
        error,
      };
    }

    // console.log(
    //   "get credentials token result",
    //   JSON.stringify(tokenInfo, null, 4)
    // );

    return tokenInfo;
  };

  return getToken;
};
