/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const BoxIcon = forwardRef(
  (
    {
      width = 246,
      height = 150,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 246 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...restProps}
    >
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3934 28.1022C51.9231 20.1771 54.2772 15.2428 56.452 13.2975C62.4747 7.91329 70.8019 10.8436 72.5723 11.2633C78.7915 12.7417 76.7693 3.01306 82.1052 1.03558C85.6612 -0.282118 88.5858 1.32861 90.8808 5.86775C92.9125 1.63298 96.0088 -0.306694 100.17 0.0392711C106.412 0.562946 108.596 21.4551 117.173 16.8819C125.752 12.3069 136.269 11.2614 140.762 18.0597C141.733 19.5306 142.105 17.2487 148.71 9.45785C155.314 1.66512 161.9 -1.76807 175.41 2.76918C181.553 4.82985 186.605 10.4163 190.571 19.5268C190.571 32.5298 200.224 40.228 219.527 42.6157C248.484 46.1983 226.007 77.0497 190.571 86.0845C155.133 95.1212 73.5453 99.9987 29.2707 77.1688C-0.245695 61.952 6.46188 45.5952 49.3915 28.1022H49.3934Z"
        fill="url(#paint0_linear_1478_114778)"
      />
      <path
        d="M122.001 143.008C151.488 143.008 175.393 137.525 175.393 130.761C175.393 123.998 151.488 118.515 122.001 118.515C92.5136 118.515 68.6094 123.998 68.6094 130.761C68.6094 137.525 92.5136 143.008 122.001 143.008Z"
        fill="url(#paint1_linear_1478_114778)"
      />
      <path
        opacity="0.675"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.141 146.042C171.997 156.021 34.9704 144.607 20.3858 139.395C13.2871 136.856 6.88862 132.083 1.19215 125.073C0.554582 124.289 0.153834 123.342 0.0362092 122.342C-0.0814157 121.342 0.0888841 120.33 0.527429 119.422C0.965974 118.513 1.65486 117.747 2.51449 117.21C3.37413 116.674 4.36941 116.389 5.38533 116.39H244.832C249.802 129.506 238.905 139.39 212.141 146.042Z"
        fill="url(#paint2_linear_1478_114778)"
      />
      <path
        d="M162.971 97.94L144.713 76.7812C144.281 76.2765 143.744 75.8699 143.139 75.5893C142.535 75.3087 141.876 75.1607 141.208 75.1554H101.947C100.6 75.1554 99.32 75.7679 98.4425 76.7812L80.1836 97.94V109.555H162.971V97.94Z"
        fill="url(#paint3_linear_1478_114778)"
      />
      <path
        d="M157.797 109.983L141.816 91.5538C141.429 91.1186 140.953 90.771 140.419 90.5345C139.885 90.298 139.306 90.1782 138.721 90.1831H104.432C103.255 90.1831 102.104 90.6671 101.337 91.5538L85.3564 109.983V120.104H157.797V109.983Z"
        fill="url(#paint4_linear_1478_114778)"
      />
      <path
        d="M162.971 122.867C162.971 124.479 162.239 125.929 161.083 126.901L160.848 127.09C159.931 127.778 158.812 128.15 157.662 128.149H85.4947C84.8423 128.149 84.2184 128.031 83.6423 127.816L83.3561 127.702C82.4121 127.286 81.61 126.608 81.0469 125.749C80.4839 124.891 80.184 123.889 80.1836 122.865V98.0667H100.27C102.489 98.0667 104.276 99.8759 104.276 102.071V102.099C104.276 104.296 106.085 106.067 108.304 106.067H134.851C135.918 106.066 136.941 105.647 137.695 104.9C138.45 104.153 138.876 103.141 138.879 102.084C138.879 99.8797 140.668 98.0667 142.885 98.0667H162.973L162.971 122.867Z"
        fill="url(#paint5_linear_1478_114778)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1478_114778"
          x1="127.211"
          y1="69.8548"
          x2="127.211"
          y2="-16.5108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDEDE" stopOpacity="0" />
          <stop offset="1" stopColor="#A9A9A9" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1478_114778"
          x1="116.438"
          y1="143.008"
          x2="116.438"
          y2="118.515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#96A1C5" stopOpacity="0.373" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1478_114778"
          x1="123"
          y1="150"
          x2="123"
          y2="109.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#919191" stopOpacity="0.15" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1478_114778"
          x1="121.577"
          y1="75.1554"
          x2="121.577"
          y2="90.6182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5389F5" />
          <stop offset="1" stopColor="#416FDC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1478_114778"
          x1="131.244"
          y1="120.104"
          x2="131.244"
          y2="88.5924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FF" />
          <stop offset="1" stopColor="#B6CFFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1478_114778"
          x1="121.578"
          y1="98.0667"
          x2="121.578"
          y2="128.149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CA5F7" />
          <stop offset="1" stopColor="#C4D6FC" />
        </linearGradient>
      </defs>
    </svg>
  )
);

export default BoxIcon;
