import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <Navigate
              replace
              to={`/insights/:projectId/dashboards/:dashboardId`}
            />
          }
        />
        <Route
          path={`/insights/:projectId/dashboards/:dashboardId`}
          element={<Dashboard />}
        />
      </Routes>
    </>
  );
}

export default App;
