/** @format */

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from "react";
import { path } from "ramda";
import styled from "styled-components";
import { Tooltip } from "antd";
import CssLoading from "./CssLoading";

interface HintProps {
  children?: any;
  content?: string | ReactNode;
  overlayClassName?: string;
  style?: {};
  size?: any;
}

export const ResizeCssLoading = ({
  size,
  style = {},
  text,
  afterOpacity,
  ...restProps
}: {
  size: string;
  style?: {};
  text?: string;
  afterOpacity?: number;
}) => {
  const spinSize = {
    small: 0.2,
    middle: 0.4,
    large: 1,
  };

  return (
    <CssLoading
      {...restProps}
      style={{ transform: `scale(${path([size], spinSize)})`, ...style }}
      text={text || (size === "large" && "Loading")}
      afterOpacity={afterOpacity}
    />
  );
};

export default ResizeCssLoading;
