import manager, { TokenManager } from "./manager";
import { TokenOptions, Token } from "./rest";

let tokenHelper: TokenManager | null = null;

export type { TokenOptions, Token };

export const init = async (options: TokenOptions): Promise<TokenManager> => {
  tokenHelper = await manager(options);
  return tokenHelper;
};

export const getAndLockToken = (): Promise<Token> => {
  return tokenHelper?.getAndLockToken();
};

export const unlockToken = (): void => {
  tokenHelper?.unlockToken();
};
