/** @format */

import { path, map } from "ramda";
import { useQueryClient, useMutation } from "react-query";

import { Toast } from "components/shared/elements/Toast";

export const onError = (e: any) => {
  if (e && e.code !== 409) {
    Toast(
      {
        message: e.message || "Failed",
      },
      "error"
    );
  }
};

export const useUpdateData = <ParamType = any>(
  queryKey: any,
  mutationFn: (value: ParamType) => any,
  options?: any,
  customError?: boolean
) => {
  const queryClient = useQueryClient();
  const defaultOptions = {
    onSuccess: () => {
      if (queryKey) {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: (e: any) => {
      if (!customError) {
        onError(e);
      }
    },
  };
  return useMutation(mutationFn, options || defaultOptions);
};

const mins = 5 * 60 * 1000;

export const queryOptions = { retry: 1 };

export const queryCacheOptions = {
  refetchOnWindowFocus: false,
  refetchInterval: mins - 1000,
};
