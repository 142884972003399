/** @format */

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { path } from "ramda";

import { routerName } from "utils/constants";

const StyledLink = styled(Link)`
  color: #228fff !important;
  margin-top: 7px;
  display: block;
`;

const Item = ({
  data,
  projectId,
  errors,
}: {
  data: any;
  projectId: string | undefined;
  errors: any;
}) => {
  const noEnv = (
    <div style={{ textAlign: "center" }}>
      <div>No data source, please add it in Environment page</div>
      {/* <StyledLink to={`/${routerName}/${projectId}/environments`}>
        Environment page
      </StyledLink> */}
    </div>
  );

  let displayMsg: any = "No data";
  const envElements: [] = path(["elements"], data) || [];

  if (envElements.length < 1) {
    displayMsg = noEnv;
  } else {
    if (errors) {
      displayMsg = errors;
    }
  }

  return <>{displayMsg}</>;
};

export default Item;
