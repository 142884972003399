/** @format */

import React, { FC } from "react";
import { Spin } from "antd";
import { path } from "ramda";

import CssLoading from "./CssLoading";

export const ResizeCssLoading = ({
  size,
  style = {},
  text,
  afterOpacity,
  ...restProps
}: {
  size: string;
  style?: {};
  text?: string;
  afterOpacity?: number;
}) => {
  const spinSize = {
    small: 0.2,
    middle: 0.4,
    large: 1,
  };

  return (
    <CssLoading
      {...restProps}
      style={{ transform: `scale(${path([size], spinSize)})`, ...style }}
      text={text || (size === "large" && "Loading")}
      afterOpacity={afterOpacity}
    />
  );
};

interface StyledSpinProps {
  spinning?: boolean;
  children?: any;
  size?: string;
  delay?: number;
  style?: React.CSSProperties;
  wrapperClassName?: string;
  className?: string;
}

const StyledSpin: FC<StyledSpinProps> = ({
  spinning = false,
  size = "large",
  delay = 0,
  children,
  style,
  wrapperClassName,
  className,
}) => {
  const customLoading = (
    <ResizeCssLoading size={size} style={{ height: "100vh" }} />
  );

  return (
    <Spin
      style={style}
      wrapperClassName={wrapperClassName}
      className={className}
      indicator={customLoading}
      spinning={spinning}
      delay={delay}
    >
      {children}
    </Spin>
  );
};

export default StyledSpin;
