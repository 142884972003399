/* eslint-disable import/no-anonymous-default-export */
import rest, { TokenInfo, Token, TokenOptions } from "./rest";

export interface TokenManager {
  getAndLockToken: Function;
  unlockToken: Function;
}

export default (options: TokenOptions): TokenManager => {
  let nextGetTokenTime = 0;
  let tokenInfo: Token | null = null;
  let lastGetTokenError: string | Error | null = null;

  let fetchTokenResolve: any = null;
  let tokenObservers: Function[] = [];
  let currentTokenKeeps: number = 0;

  let fetchRestToken = rest(options);

  let fetchToken = async () => {
    const result: TokenInfo = await fetchRestToken();

    if (!result.error) {
      let expiresIn = parseInt(result?.expiresIn?.toString() || "0");
      let currentTime = new Date().getTime();
      nextGetTokenTime = currentTime + Math.floor((expiresIn / 5) * 4) * 1000;
      tokenInfo = result.token;
      lastGetTokenError = null;
    } else {
      lastGetTokenError = result.error;
      tokenInfo = null;
      nextGetTokenTime = 0;
    }
  };

  let getAndLockToken = async () => {
    let currentTime = new Date().getTime();

    if (fetchTokenResolve) {
      return await new Promise((resolve, reject) => {
        tokenObservers.push(
          ({ error, token }: { error: any; token: string | null }) => {
            if (error) {
              reject(error);
            } else {
              currentTokenKeeps += 1;
              resolve(token);
            }
          }
        );
      });
    }

    if (currentTime >= nextGetTokenTime) {
      if (currentTokenKeeps > 0) {
        await new Promise((resolve) => {
          fetchTokenResolve = resolve;
        });
      } else {
        fetchTokenResolve = () => {};
      }
      await fetchToken();
    }

    tokenObservers.forEach((observer: Function) => {
      observer({
        token: tokenInfo,
        error: lastGetTokenError,
      });
    });
    tokenObservers = [];

    fetchTokenResolve = null;

    if (lastGetTokenError) {
      throw lastGetTokenError;
    }

    currentTokenKeeps += 1;
    return tokenInfo;
  };

  let unlockToken = () => {
    currentTokenKeeps -= 1;
    if (currentTokenKeeps < 0) {
      currentTokenKeeps = 0;
    }
    if (currentTokenKeeps === 0) {
      if (fetchTokenResolve) {
        fetchTokenResolve();
      }
    }
  };

  return {
    getAndLockToken,
    unlockToken,
  };
};
