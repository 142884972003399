/** @format */

import React, { useState, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";
import { NavLink, useParams } from "react-router-dom";
import { range, map, filter, path } from "ramda";
import type { MenuProps } from "antd";
import { Dropdown, Popconfirm, Radio } from "antd";
import { sharedUtils, sharedOptions } from "horizon-shared-lib";

import DashboardContext from "hook/dashboard/context";
import Hint from "components/shared/elements/Hint";
import RefreshIcon from "assets/Refresh";
import { auid, getLabel, processConfig, createAt } from "utils/stringUtil";
import { routerName } from "utils/constants";

const { GetDateString, formatDate, deconstructionDate } = sharedUtils;
const { timeScopeOptions } = sharedOptions;

const StyledHeader = styled.div`
  height: 43px;
  padding-bottom: 2px;
`;

const StyledHeaderLeft = styled.div`
  padding-right: 5px;
  width: 63%;
  display: flex;
  align-items: center;
`;

const StyledHeaderRight = styled.div`
  display: flex;
`;

const StyledHint = styled(Hint)`
  color: ${(props) => props.theme.colors.primary};
`;

const StyledDateHint = styled(Hint)`
  color: ${(props) => props.theme.colors.primary2};
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  fill: #949494;
  margin-left: 10px;
  vertical-align: -2px;
  cursor: pointer;
  :hover {
    fill: #229bff;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export const StyledBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const nameStyle = {
  fontSize: "16px",
};

const dateStyle = {
  // color: "#949494",
  fontSize: "12px",
};

const Item = ({
  item,
  dataUpdateAt,
  projectCreateAt,
  isActive,
  setActive,
  nodeRef,
  timeScope,
  setTimeScope,
  onRefresh = () => {},
}: {
  item?: any;
  projectCreateAt: string;
  dataUpdateAt: string;
  isActive: boolean;
  setActive: any;
  nodeRef: any;
  timeScope: string;
  setTimeScope: any;
  onRefresh?: (i: boolean) => void;
}) => {
  const { state } = useContext(DashboardContext);

  const { dashboard } = state;
  const params = useParams();
  const projectId = path(["projectId"], params);
  const [open, setOpen] = useState(false);

  const { layout } = item;

  const widgetInfo = useMemo(() => {
    return item.widget;
  }, [item]);

  const id = path(["id"], widgetInfo);
  const widgetType = path(["widgetType"], widgetInfo);
  const chartType = path(["chartType"], widgetInfo);
  const unit = path(["analysis", "unit"], widgetInfo);
  let endAt = path(["analysis", "endAt"], widgetInfo);
  let startAt = path(["analysis", "startAt"], widgetInfo);
  const config = processConfig(path(["config"], widgetInfo));

  const dynamicTime = path(["dynamicTime"], config);

  if (dynamicTime) {
    const dates = deconstructionDate(dynamicTime, projectCreateAt);
    startAt = formatDate(dates[0]);
    endAt = formatDate(dates[1]);
  } else {
    startAt = GetDateString(startAt);
    endAt = GetDateString(endAt);
  }

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setActive(false);
      }, 100);
    }
  }, [open, setActive]);

  const widgetWidth = useMemo(() => {
    return path(["w"], layout);
  }, [layout]);

  const linkStyle: any = {
    transition: "0.2s color",
  };
  if (isActive) {
    linkStyle.color = "#229BFF";
  }
  const analysisUrl = (analysisType: string) => {
    return `/${routerName}/${projectId}/analysis/${analysisType}/${id}/${dashboard.id}`;
  };

  let link = analysisUrl("event-analysis");

  if (widgetType === "FUNNEL") {
    link = analysisUrl("funnel");
  }

  if (widgetType === "RETENTION") {
    link = analysisUrl("retention");
  }

  return (
    <StyledHeader>
      <StyledBetween style={{ marginBottom: "3px" }}>
        <StyledHeaderLeft
          className="nonDraggable"
          data-auid={auid("insights", "dashboard", `widget_${widgetInfo.name}`)}
        >
          <StyledHint
            content={widgetInfo.name}
            style={{ ...nameStyle, ...linkStyle }}
            size={widgetWidth}
          />

          {chartType === "TABLE" && (
            <Radio.Group
              style={{ marginLeft: "5px" }}
              value={timeScope}
              size="small"
              onChange={(e) => setTimeScope(e.target.value)}
            >
              <Radio.Button value="TOTAL">Total</Radio.Button>
              <Radio.Button value={unit}>
                {getLabel(unit, timeScopeOptions)}
              </Radio.Button>
            </Radio.Group>
          )}
        </StyledHeaderLeft>

        <StyledHeaderRight>
          <div></div>
        </StyledHeaderRight>
      </StyledBetween>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StyledDateHint
          content={`${startAt} ~ ${endAt}${
            dataUpdateAt ? ` | Updated at ${dataUpdateAt}` : ""
          }`}
          overlayClassName="nonDraggable"
          style={dateStyle}
          data-auid={auid("insights", "dashboard", `widget_date`)}
        />

        <StyledRefreshIcon
          className="nonDraggable hover-show"
          data-auid={auid("insights", "dashboard", `widget_refresh_btn`)}
          onClick={() => onRefresh(true)}
        />
      </div>
    </StyledHeader>
  );
};

export default Item;
