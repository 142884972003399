/**
 * /* eslint-disable max-lines
 *
 * @format
 */

export const DASHBOARD_DETAIL = "DASHBOARD/detail";
export const DASHBOARD_WIDGET = "DASHBOARD/widgets";
export const PROJECT_ID = "PROJECT_ID";
export const PROJECT_DETAIL = "PROJECT/detail";

const initState = {
  widgets: [],
  dashboard: {},
  projectId: "",
  project: {},
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case PROJECT_ID:
      return { ...state, projectId: action.payload || "" };
    case PROJECT_DETAIL:
      return { ...state, project: action.payload || {} };
    case DASHBOARD_WIDGET:
      return { ...state, widgets: action.payload || [] };
    case DASHBOARD_DETAIL:
      return { ...state, dashboard: action.payload || {} };
    default:
      return state;
  }
};

const dashboardReducer = { initState, reducer };

export default dashboardReducer;
