import React, { useState, useMemo, useContext } from "react";
import { path, map } from "ramda";
import { useParams } from "react-router-dom";
import RGL from "react-grid-layout";

import WidthProvider from "components/shared/WidthProvider";
import DashboardContext from "hook/dashboard/context";
import Item from "components/Dashboards/Item";

function Layout({ onDrag, onDragStop }: { onDrag?: any; onDragStop?: any }) {
  const ReactGridLayout = useMemo(() => WidthProvider(RGL), []);

  const { state } = useContext(DashboardContext);
  const [defaultProps, setdefaultProps] = useState<any>({
    className: "layout",
    items: 10,
    rowHeight: 31,
    margin: [16, 16],
    containerPadding: [0, 16],
    onLayoutChange: function () {},
    cols: 4,
    isDraggable: true,
    isResizable: false,
    draggableCancel: ".nonDraggable",
  });

  const { widgets } = state;

  const transformLayout = map(
    (i: any) => ({ ...i.layout, i: i.widgetId }),
    widgets || []
  );

  return (
    <>
      <ReactGridLayout layout={transformLayout} {...defaultProps}>
        {map(
          (i: any) => (
            <div key={i.widgetId}>
              <Item i={i} />
            </div>
          ),
          widgets || []
        )}
      </ReactGridLayout>
    </>
  );
}

export default Layout;
