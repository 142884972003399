import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider, theme } from "antd";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GlobalStyle from "./globalStyle";
import ContextProvider from "./ContextProvider";
import getTheme from "theme";
import * as api from "api";
import { apiBase, oauthURL } from "utils/constants";

console.log("oAuth api url", oauthURL);

function App() {
  const queryClient = new QueryClient({});
  const urlParams = new URLSearchParams(window.location.search);
  const urltheme = urlParams.get("theme") || "";
  const projectId = urlParams.get("projectId") || "";
  const urlSign = urlParams.get("sign") || "";
  //   const sign: { id: string; secret: string } = urlSign
  //     ? JSON.parse(atob(urlParams.get("sign") || ""))
  //     : "";

  const [isTokenLoaded, setTokenLoaded] = useState(false);

  useEffect(() => {
    if (!isTokenLoaded) {
      const init = async () => {
        await api.init("state", apiBase, {
          clientId: urlSign,
          accessTokenUri: oauthURL,
        });
        setTokenLoaded(true);
      };
      init();
    }
  }, [isTokenLoaded, urlSign]);

  const componentsTheme = getTheme(urltheme);

  let algorithm = theme.defaultAlgorithm;
  if (urltheme === "dark") {
    algorithm = theme.darkAlgorithm;
  }

  return (
    <>
      {isTokenLoaded && (
        <ThemeProvider theme={componentsTheme}>
          <ConfigProvider
            theme={{
              token: { fontFamily: "Roboto" },
              algorithm: algorithm,
              ...componentsTheme.antd,
            }}
          >
            <GlobalStyle />
            <QueryClientProvider client={queryClient}>
              <ContextProvider theme={urltheme} projectId={projectId} />
            </QueryClientProvider>
            <ToastContainer newestOnTop={true} limit={3} />
          </ConfigProvider>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
