import React, { useRef } from "react";
import clsx from "clsx";
import ResizeObserver from "resize-observer-polyfill";
import { debounce } from "throttle-debounce";

const layoutClassName = "react-grid-layout";

export default function WidthProvideRGL(ComposedComponent: any) {
  return class WidthProvider extends React.Component {
    static defaultProps: any = {
      measureBeforeMount: false,
    };

    // static propTypes = {
    //   measureBeforeMount: PropTypes.bool,
    // };

    state = {
      width: 1280,
      observer: null,
    };

    elementRef: any = React.createRef();
    mounted = false;

    onDivMutation = () => {
      const element: any = document.querySelector(".react-grid-layout");

      const throttleFunc = debounce(10, () => {
        this.onWindowResize();
      });

      new ResizeObserver((entries) => {
        for (const entry of entries) {
          switch (entry.target) {
            case element:
              throttleFunc();
              break;
          }
        }
      }).observe(element);
    };

    componentDidMount() {
      this.mounted = true;
      // window.addEventListener("resize", this.onWindowResize);
      this.onDivMutation();
      // Call to properly set the breakpoint and resize the elements.
      // Note that if you're doing a full-width element, this can get a little wonky if a scrollbar
      // appears because of the grid. In that case, fire your own resize event, or set `overflow: scroll` on your body.
      this.onWindowResize();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.onWindowResize);
    }

    // onWindowResize = () => {
    //   if (!this.mounted) return;
    //   let _ = this;
    //   let node: any = "";
    //   setTimeout(() => {
    //     node = this.elementRef.current;
    //     if (node instanceof HTMLElement && node.offsetWidth) {
    //       _.setState({ width: node.offsetWidth });
    //     }
    //   }, 0);
    // };

    onWindowResize = () => {
      if (!this.mounted) return;
      const node = this.elementRef.current; // Flow casts this to Text | Element
      // fix: grid position error when node or parentNode display is none by window resize
      // #924 #1084
      if (node instanceof HTMLElement && node.offsetWidth) {
        this.setState({ width: node.offsetWidth });
      }
    };

    render() {
      const { measureBeforeMount, className, style, ...rest }: any = this.props;
      if (measureBeforeMount && !this.mounted) {
        return (
          <div
            className={clsx(className, layoutClassName)}
            style={style}
            ref={this.elementRef}
          />
        );
      }

      return (
        <ComposedComponent
          innerRef={this.elementRef}
          {...rest}
          {...this.state}
        />
      );
    }
  };
}
