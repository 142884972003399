/** @format */

export const apiBase =
  window.REACT_APP_API_BASE || process.env.REACT_APP_API_BASE;
export const routerName =
  window.REACT_APP_ROUTER_NAME || process.env.REACT_APP_ROUTER_NAME;
export const TenantId =
  window.REACT_APP_SHELL_TENANT_ID || process.env.REACT_APP_SHELL_TENANT_ID;
export const oauthURL =
  window.REACT_APP_OAUTH_URL || process.env.REACT_APP_OAUTH_URL; // https://oauth-dev.hpbp.io/oauth/v1/token
