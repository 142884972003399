/** @format */

import React, { ReactNode } from "react";
import styled from "styled-components";
import EmptyBox from "assets/EmptyBox";
import { auid } from "utils/stringUtil";

const StyledItem = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

const StyledDescription = styled.div`
  color: ${(props) => props.theme.colors.description};
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 400;
`;

function Empty({
  title = "",
  description = "",
  style,
}: {
  title?: string | ReactNode;
  description?: string | ReactNode;
  style?: {};
}) {
  return (
    <StyledItem style={style}>
      <EmptyBox />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledItem>
  );
}

export default Empty;
