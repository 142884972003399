import dark from "./dark";
import light from "./light";

const getTheme = (theme: string) => {
  switch (theme) {
    case "light":
      return light;
    case "dark":
      return dark;
    default:
      return light;
  }
};

export default getTheme;
